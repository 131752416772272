import { Controller } from "stimulus";

export default class extends Controller {

  validateQuestions(event) {
    const questions = document.getElementsByClassName("nested-fields");

    let savable = true;
    for (var i = 0; i < questions.length; i++) {
      var doBreak = false;
      var id = $($(questions[i]).find(".js-position-input")[0])
          .attr("name")
          .match(/\d+/g)[0];
      var question = document.getElementsByName(`activity[activity_custom_questions_attributes][${id}][question_text]`)[0];
      var multipleChoice = document.getElementById(`activity_activity_custom_questions_attributes_${id}_multiple_choice`).checked;
      var correctAnswer = document.getElementsByName(`activity[activity_custom_questions_attributes][${id}][correct_answer]`)[0]

      // check question is present
      if (question.value == "") {
        savable = false;
        $(question).addClass("border-red-500");
        alert("You cannot submit a blank question");
        break;
      } else {
        $(question).removeClass("border-red-500");
      }
      // check correct answer present
      if (correctAnswer.value == "") {
        savable = false;
        $(correctAnswer).addClass("border-red-500");
        alert("All questions must have a correct answer.");
        break;
      } else {
        $(correctAnswer).removeClass("border-red-500");
      }
      // check incorrect answers if multi choice question
      if (multipleChoice) {
        var incorrectAnswers = document.getElementsByName(`activity[activity_custom_questions_attributes][${id}][incorrect_answers][]`)
        for (var j=0; j < incorrectAnswers.length; j++) {
          if (incorrectAnswers[j].value == "") {
            savable = false;
            $(incorrectAnswers[j]).addClass("border-red-500");
            alert("You cannot have blank incorrect answers to questions.");
            doBreak = true;
            break;
          } else {
            $(incorrectAnswers[j]).removeClass("border-red-500");
          }
        }
      }
      if (doBreak) { break };
    }

    if (!savable) {
      event.preventDefault();
    }
  }
}