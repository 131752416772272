import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    id: Number,
    name: String,
    company: String
  }

  connect() {
    const closeModalBtn = document.getElementById("close_customer_modal")
    closeModalBtn.click()

    let name = this.nameValue;
    if (this.companyValue.length) { name += ` - ${this.companyValue}` }

    const tomSelect = this.editActivityController.tomSelector
    tomSelect.addOption({ id: this.idValue, name_and_company: name }, false)
    tomSelect.addItem(this.idValue, false)
    window.setTimeout(() => {
      this.editActivityController.syncTomSelect()
    }, 350)
  }

  get editActivityController() {
    const mainElement = document.getElementById("edit_activity_details")
    return this.application.getControllerForElementAndIdentifier(mainElement, "edit-activity")
  }
}
