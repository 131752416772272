import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.sortPosition();
  }

  AddNewTeam() {
    this.sortPosition();
  }

  RemoveTeam() {
    this.sortPosition();
  }

  sortPosition() {
    $(".nested-fields:visible").each((idx, item) => {
      const number = idx + 1;
      if ($(item).data("new-record") == true) {
        const id = $($(item).find(".js-position-input")[0])
          .attr("name")
          .match(/\d+/g)[0];
        
        $(item).find("textarea.team-list").data("position", number)
          .attr("data-position", number)
          .attr("name", `activity[activity_team_lists_attributes][${id}][player_list][]`);
      }
      $(item).find(".js-position-input").val(number);
      $(item).find(".js-team-position").text(number);
    });
  }
}
