import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "chargeCustomer",
    "bookedPpx",
    "finalPpx",
    "message",
    "submit"
  ]

  static values = {
    bookedPpx: Number,
    finalPpx: Number
  }

  connect() {
    this.updateMessage();
  }

  updateMessage() {
    const $this = this;
    
    setTimeout(function() {
      const finalPpx = $this.finalPpxTarget.value;

      if(finalPpx === "") {
        $this.messageTarget.innerHTML = "Please enter a final value for the number of participants who attended the event";
        Rails.disableElement($this.submitTarget);
      } else {
        $this.messageTarget.innerHTML = $this.generateMessage();
        Rails.enableElement($this.submitTarget);
      }
    }, 10);
  }

  confirmPax(event) {
    const msg = this.generateMessage();

    if(!confirm(msg)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  generateMessage() {
    const checked = this.chargeCustomerTarget.checked;
    const bookedPpx = Number(this.bookedPpxTarget.defaultValue);
    const finalPpx = this.finalPpxTarget.value;
    let msg = "The customer ";

    if(!checked) {
      msg +=  "will not be charged/credited for any differences in participants.";
    } else if(checked) {  
      const diff = bookedPpx - finalPpx; 
      if(bookedPpx > finalPpx) {
        msg += "will be credited the cost of " + diff + " participants.";
      } else if(bookedPpx < finalPpx) {
        msg += "will be billed for the cost of " + Math.abs(diff) + " extra participants.";
      } else {
        msg += "will not be charged as the final participant count did not change."
      }
    }
    return msg;
  }
}
