import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["currencyInput"];

  connect() {
    this.loadDefaults(false)
  }

  loadDefaults(overwriteExistingValues = true) {
    let currency = this.currencyInputTarget.value
    let rates = JSON.parse(this.data.get("rates"))
    var rate = rates.find(rate => rate.currency == currency)

    // remove 'currency' attribute from hash, then we iterate over the remaining values
    delete rate["currency"]

    Object.keys(rate).forEach(function (key) { 
      let value = rate[key]
      let elements = document.querySelectorAll(`[name*="${key.replace("_cents", "")}"]`)
      let element = (elements.length > 0) ? elements[0] : null
      if (element && (overwriteExistingValues || element.value.length == 0)) {
        element.value = (parseFloat(value) / 100).toFixed(2)
      }
    })
  }
}
