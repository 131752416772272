import { Controller } from "stimulus";
let _ = require('lodash')
export default class extends Controller {
  
  validateTeamLists(event) {
    const teamLists = document.getElementsByClassName("team-list");
    let savable = true;

    if (teamLists.length > 0) {
      _.forEach(teamLists, function(teamList) {
        if (!$(teamList).is(':visible')) return

        const list = _.compact(teamList.value.split('\n'))
        const teamNumber = teamList.dataset.position;

        if (list.length == 1  || list.length > 8) {
          savable = false;
          $(teamList).addClass("border-red-500");
  
          alert(`You need to have 2-8 players on Team ${teamNumber}. We recommend 4-5 for the best experience.`);          
        } else if (list.length == 0) {
          savable = false;
          $(teamList).addClass("border-red-500");
  
          alert(`You need to add players to Team ${teamNumber} or delete it.`);
        }
      })
    } 

    if (!savable) {
      event.preventDefault();
      return false 
    } else {
      return true
    }
  }
}
