import { Controller } from "stimulus"
let debounce = require('lodash.debounce');
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    previewInvoiceUrl: String,
    dateInputId: String,
    customerSelectUrl: String,
    regionSelectUrl: String
  }
  static targets = [
    "previewInvoiceLoading",
    "previewInvoiceFrame",
    "previewInvoiceTotal",
    "previewInvoiceInputParameter",
    "customerSelectField",
    "customerSelectActualInput",
    "regionSelectField",
    "regionSelectActualInput",
    "createCustomerForm",
    "themeInput",
    "deliveryTypesFrame",
    "deliveryTypeInput",
    "deliveryTypeWarning",
    "variantsFrame",
    "variantInput",
    "variantWarning",
    "submitButton"
  ]

  initialize() {
    this.sendPreviewRequest = debounce(this.sendPreviewRequest, 500).bind(this)
  }

  connect() {
    let errorEl = this.element.querySelector('.form-group-invalid');
    if (errorEl) {
      errorEl.scrollIntoView();
      this.sendPreviewRequest();
    }
    this.setupCustomerSelects()
    this.setupRegionSelects()
    this.validateThemeTypeandVariant()
  }

  refreshInvoicePreview() {
    if (this.hasPreviewInvoiceLoadingTarget) { this.previewInvoiceLoadingTarget.classList.remove("hidden") }
    if (this.hasPreviewInvoiceTotalTarget) { this.previewInvoiceTotalTarget.innerHTML = "..."; }
    this.sendPreviewRequest();
  }

  sendPreviewRequest() {
    if (this.hasPreviewInvoiceFrameTarget) { this.previewInvoiceFrameTarget.src = this.calculateInvoicePreviewUrl(); }
  }

  calculateInvoicePreviewUrl() {
    // get all previewInvoiceInputParameterTargets and use their name to construct URL
    let url = new URL(this.previewInvoiceUrlValue);

    // date param
    let dateIdConstructor = this.dateInputIdValue
    let selectedDate = new Date(Date.UTC(
      $(`#${dateIdConstructor}_1i`).val(),
      $(`#${dateIdConstructor}_2i`).val() - 1,
      $(`#${dateIdConstructor}_3i`).val(),
      $(`#${dateIdConstructor}_4i`).val(),
      $(`#${dateIdConstructor}_5i`).val()
    ));
    url.searchParams.set("event_date", selectedDate.toISOString());

    // all other params
    this.previewInvoiceInputParameterTargets.forEach((el) => {
      if (el.type == "checkbox") {
        url.searchParams.set(el.getAttribute("data-parameter-name"), el.checked);
      } else {
        url.searchParams.set(el.getAttribute("data-parameter-name"), el.value);
      }
    });

    return url;
  }

  get tomSelector() {
    return this.tomSelect
  }

  get tomSelectorActualInputTarget() {
    return this.tomSelectActualInputTarget
  }

  syncCustomerSelect() {
    let select = this.customerSelectActualInputTarget
    Array.from(select.options).forEach((option) => {
      select.removeChild(option)
    })

    this.tomSelect.items.forEach((item) => {
      var opt = document.createElement('option')
      opt.appendChild(document.createTextNode(item));
      opt.value = item
      opt.selected = true
      select.appendChild(opt);
    })
  }

  setupCustomerSelects() {
    if (!this.hasCustomerSelectFieldTarget) return

    this.tomSelect = new TomSelect(this.customerSelectFieldTarget, {
      valueField: 'id',
      labelField: 'name_and_company',
      searchField: 'name_and_company',
      create: false,
      maxOptions: 5,
      closeAfterSelect: true,
      // minimum query length
      shouldLoad: function (query) {
        return query.length > 1;
      },
      onItemAdd: (item) => {
        this.syncCustomerSelect()
      },
      onItemRemove: (item) => {
        this.syncCustomerSelect()
      },
      // fetch remote data
      load: (query, callback) => {
        const url = this.customerSelectUrlValue + '?filter[term]=' + encodeURIComponent(query);
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json.items);
          }).catch(() => {
            callback();
          });
      },
      // custom rendering functions for options and items
      render: {
        option: function (item, escape) {
          return `<div class="border-b py-1">
                <div class="w-full">
                  <div class="mt-0">${escape(item.name_and_company)}</div>
                </div>
              </div>`;
        },
        item: function (item, escape) {
          return `<div class="py-1 px-3 bg-gray-200 rounded-md flex mb-1 font-semibold">
                    ${escape(item.name_and_company)}
                  </div>`;
        }
      }
    });
  }

  get regionSelector() {
    return this.regionSelect
  }

  get regionSelectorActualInputTarget() {
    return this.regionSelectActualInputTarget
  }

  syncRegionSelect() {
    let select = this.regionSelectActualInputTarget
    Array.from(select.options).forEach((option) => {
      select.removeChild(option)
    })

    this.regionSelect.items.forEach((item) => {
      var opt = document.createElement('option')
      opt.appendChild(document.createTextNode(item));
      opt.value = item
      opt.selected = true
      select.appendChild(opt);
    })
  }

  setupRegionSelects() {
    if (!this.hasRegionSelectFieldTarget) return

    this.regionSelect = new TomSelect(this.regionSelectFieldTarget, {
      valueField: 'id',
      labelField: 'region_and_country',
      searchField: 'region_and_country',
      create: false,
      maxOptions: 5,
      closeAfterSelect: true,
      // minimum query length
      shouldLoad: function (query) {
        return query.length > 1;
      },
      onItemAdd: (item) => {
        this.syncRegionSelect()
      },
      onItemRemove: (item) => {
        this.syncRegionSelect()
      },
      // fetch remote data
      load: (query, callback) => {
        const url = this.regionSelectUrlValue + '?filter[term]=' + encodeURIComponent(query);
        fetch(url)
          .then(response => response.json())
          .then(json => {
            callback(json.items);
          }).catch(() => {
            callback();
          });
      },
      // custom rendering functions for options and items
      render: {
        option: function (item, escape) {
          return `<div class="border-b py-1">
                <div class="w-full">
                  <div class="mt-0">${escape(item.region_and_country)}</div>
                </div>
              </div>`;
        },
        item: function (item, escape) {
          return `<div class="py-1 px-3 bg-gray-200 rounded-md flex mb-1 font-semibold">
                    ${escape(item.region_and_country)}
                  </div>`;
        }
      }
    });
  }

  toggleCreateCustomer(event) {
    event.preventDefault()

    this.tomSelect.clear()
    this.createCustomerFormTarget.classList.toggle('hidden')
  }

  reloadDeliveryTypes(event) {
    let $id = event.currentTarget.value;
    // allow for self managed themes - no delivery type
    if (this.hasDeliveryTypesFrameTarget) {
      this.deliveryTypesFrameTarget.src = this.deliveryTypesFrameTarget.getAttribute("data-original-src") + "?theme_id=" + $id;
    }
    // let the form load, then pass the preselected type to the variant form
    const $this = this
    setTimeout(function() {
      let srcUrl = $this.variantsFrameTarget.getAttribute("data-original-src") + "?theme_id=" + $id;
      if ($this.hasDeliveryTypesFrameTarget) {
        let $type = $("#activity_delivery_type :selected")[0].value
        srcUrl += "&type=" + $type
      } 
      if ($this.hasVariantsFrameTarget) {
        $this.variantsFrameTarget.src = srcUrl
      }
    }, 200)
    setTimeout(function() {
      $this.validateThemeTypeandVariant()
    }, 500)
  }

  reloadVariants(event) {
    let themeId = this.themeInputTarget.value;
    let type = event.currentTarget.value;
    if (this.hasVariantsFrameTarget) {
      this.variantsFrameTarget.src = this.variantsFrameTarget.getAttribute("data-original-src") + "?theme_id=" + themeId + "&type=" + type;
    }
    const $this = this
    setTimeout(function() {
      $this.validateThemeTypeandVariant()
    }, 200)
  }

  selectVariant() {
    this.validateThemeTypeandVariant()
  }

  validateThemeTypeandVariant() {
    let validForm = true

    if (this.hasThemeInputTarget && this.themeInputTarget.value != "") {
      if (this.hasDeliveryTypeInputTarget) {
        if (this.deliveryTypeInputTarget.value == "") {
          validForm = false
          $(this.deliveryTypeInputTarget).removeClass("border-gray-300")
          $(this.deliveryTypeInputTarget).addClass("border-red-500")
          $(this.deliveryTypeWarningTarget).removeClass("hidden")
        } else {
          $(this.deliveryTypeInputTarget).addClass("border-gray-300")
          $(this.deliveryTypeInputTarget).removeClass("border-red-500")
          $(this.deliveryTypeWarningTarget).addClass("hidden")
        }
      }
  
      if (this.variantInputTarget.value == "") {
        validForm = false
        $(this.variantInputTarget).removeClass("border-gray-300")
        $(this.variantInputTarget).addClass("border-red-500")
        $(this.variantWarningTarget).removeClass("hidden")
      } else {
        $(this.variantInputTarget).addClass("border-gray-300")
        $(this.variantInputTarget).removeClass("border-red-500")
        $(this.variantWarningTarget).addClass("hidden")
      }
  
      $(this.submitButtonTarget).prop("disabled", !validForm)
    }
  }
}
