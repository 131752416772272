import { Controller } from "stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = ["form", "input", "typeInput", "submitButton"];

  filter(event) {
    const type = $(event.currentTarget).data("type");
    if (this.hasTypeInputTarget) {
      let oldType = $(this.typeInputTarget).val();
      if (oldType == type) {
        this.clear();
        return;
      }
    }

    let start = "";
    let finish = "";
    let dateRange = "";

    // get correct range
    switch (type) {
      case "today":
        start = moment();
        finish = start;
        break;
      case "tomorrow":
        start = moment().add(1, "days");
        finish = start;
        break;
      case "this-week":
        start = moment().startOf("isoWeek");
        finish = moment().endOf("isoWeek");
        break;
      case "next-week":
        start = moment().add(1, "weeks").startOf("isoWeek");
        finish = moment().add(1, "weeks").endOf("isoWeek");
        break;
      case "in-the-next-week":
        start = moment();
        finish = moment().add(7, "days");
        break;
      case "in-the-next-two-weeks":
        start = moment();
        finish = moment().add(14, "days");
        break;
      case "this-month":
        start = moment().startOf("month");
        finish = moment().endOf("month");
        break;
      case "month-of-event":
        let el = event.currentTarget;
        let timestamp = parseInt(el.getAttribute("data-timestamp"));
        start = moment(timestamp).startOf("month");
        finish = moment(timestamp).endOf("month");
        break;
      case "yesterday":
        start = moment().subtract(1, "days");
        finish = start;
        break;
      case "last-week":
        start = moment().subtract(1, "weeks").startOf("isoWeek");
        finish = moment().subtract(1, "weeks").endOf("isoWeek");
        break;
      case "in-the-last-week":
        start = moment().subtract(7, "days");
        finish = moment();
        break;
      case "in-the-last-two-weeks":
        start = moment().subtract(14, "days");
        finish = moment();
        break;
      case "last-month":
        start = moment().subtract(1, "month").startOf("month");
        finish = moment().subtract(1, "month").endOf("month");
        break;

      default:
        //
        break;
    }

    start = start.format("DD-MM-YYYY");
    finish = finish.format("DD-MM-YYYY");

    dateRange = start + " to " + finish;

    // this is an addition to allow us to keep the
    // type selected in the form/GET parameters to
    // highlight the appropriate button that was pressed in the view.
    if (this.hasTypeInputTarget) {
      $(this.typeInputTarget).val(type);
    }
    $(this.inputTarget).val(dateRange);

    // submit form
    this.element.requestSubmit();
  }

  clear() {
    $(this.inputTarget).val("");
    if (this.hasTypeInputTarget) {
      $(this.typeInputTarget).val("");
    }

    this.element.requestSubmit();
  }
}
