import { Controller } from "stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = ["message"];

  checkDate() {
    let dateIdConstructor = this.data.get("inputId");
    let selectedDate = new Date(
      $(`#${dateIdConstructor}_1i`).val(),
      $(`#${dateIdConstructor}_2i`).val() - 1,
      $(`#${dateIdConstructor}_3i`).val(),
      $(`#${dateIdConstructor}_4i`).val(),
      $(`#${dateIdConstructor}_5i`).val()
    );

    $(this.messageTarget).toggleClass(
      "hidden",
      moment().isBefore(moment(selectedDate).add(1, "days"), "day")
    );
  }
}
