import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "button"];
  copy() {
    this.sourceTarget.select();
    this.buttonTarget.innerHTML = "Copied!";
    document.execCommand("copy");
  }
}
