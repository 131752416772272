import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["columnInput", "directionInput"];

  select(e) {
    let col = e.currentTarget.getAttribute("data-col")
    let dir = e.currentTarget.getAttribute("data-dir")

    this.directionInputTarget.value = dir
    this.columnInputTarget.value = col
    this.columnInputTarget.form.requestSubmit()
  }
}
