import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['emailInput', "sentEmailList", "submitButton", 'validateEmailWarning' ]
  
  validateSumbit(event) {
    const email = this.emailInputTarget.value;
    const sentEmailList = this.sentEmailListTarget.innerHTML;
    
    if(email === "") {
      this.validateEmailWarningTarget.classList.remove("hidden");
    } else if(sentEmailList.includes(email)){
      if(!confirm("Just a heads up, an email has already been sent to: " + email)) {
        event.preventDefault()
      }
    } 

    if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) || email === "") {
      this.validateEmailWarningTarget.classList.remove("hidden");
      event.preventDefault();
    }
  }
}