import { Controller } from "stimulus"
import moment from "moment";

export default class extends Controller {
  static targets = [
    "eventStart",
    "timeToOrSince"
  ]

  connect() {
    let $this = this;
    setInterval(function() {
      let timeNow = moment()
      let eventTime = moment($this.eventStartTarget.innerHTML)
      let res;
      if(eventTime < timeNow) {
        let timeDiff = moment.utc(timeNow.diff(eventTime)).format("mm:ss")
        res = `${timeDiff} since event start`
      } else {
        let timeDiff = moment.utc(eventTime.diff(timeNow)).format("mm:ss")
        res = `${timeDiff} to event start`
      }
  
      $this.timeToOrSinceTarget.innerHTML = res
    }, 1000)
  }
}