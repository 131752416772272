import { Controller } from "stimulus";

export default class extends Controller {
  toggle(event) {
    this.markAsLoading();
    event.currentTarget.form.requestSubmit();
  }

  markAsLoading() {
    let loader = document.querySelector("#loader")
    if (loader) { loader.classList.remove("hidden") }
  }
}
