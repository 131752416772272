import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'tagList',
    'tagButton',
    "submitMessage",
    "error"
  ];

  connect() {
    var $this = this;
    $('.check_boxes').on('change', function(e){
      e.stopImmediatePropagation();
      $this.checkValid(e);
    });
  }

  toggleShowTags(event) {
    event.preventDefault();
    $(this.tagListTarget).toggleClass("hidden");

    if ($(this.tagListTarget).is(':hidden')) {
      $(this.tagButtonTarget).text('Show Tags');
    } else {
      $(this.tagButtonTarget).text('Hide Tags');
    }
  }

  checkValid(e) {
    if($('input.check_boxes:checked').length == 0){
      $(this.errorTarget).removeClass("hidden");
      Rails.disableElement(this.submitMessageTarget);
    } else {
      $(this.errorTarget).addClass("hidden");
      Rails.enableElement(this.submitMessageTarget);
    }
  }
}