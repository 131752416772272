import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  validateButton(event){
    // decide if the button should be disabled or enabled
    if ($(event.currentTarget).is(":checked")){
      // enable the button
      $(this.buttonTarget).prop('disabled', false);
      $(this.buttonTarget).addClass("hover:bg-white cursor-pointer bg-gray-200").removeClass("bg-gray-400 cursor-not-allowed")
    } else {
      $(this.buttonTarget).prop('disabled', true);
      $(this.buttonTarget).addClass("bg-gray-400 cursor-not-allowed").removeClass("hover:bg-white cursor-pointer bg-gray-200")
    }
  }

  acceptTerms(event){
    // send an ajax request to the controller, then on success hide the banner
    $.ajax({
      type: "POST",
      url: this.data.get("url"), // /freelancers/:id/accept_terms
      data: {},
      success: function () {
        // hide the banner
        $("#accept-terms-wrapper").fadeOut( "slow" )
      },
      error: function (err) {
        console.log("failure");
      },
    });
  }
}