// Copied from https://onrails.blog/2018/05/08/stimulus-js-tutorial-dont-lose-unsaved-form-fields/

import { Controller } from "stimulus";

const LEAVING_PAGE_MESSAGE =
  "Changes that you made may not be saved. Are you sure you want to exit this page?";

export default class extends Controller {
  formIsChanged() {
    this.setChanged("true");
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault();
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    }
  }

  allowFormSubmission(event) {
    this.setChanged("false");
  }

  setChanged(changed) {
    this.data.set("changed", changed);
  }

  isFormChanged() {
    return this.data.get("changed") == "true";
  }
}
