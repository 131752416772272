import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['ppxWarning', 'ppxInput', 'inputtedEventPpx', 'bookedPpx' ]
  
  checkPpxNumbers(event) {
    const ppx = this.ppxInputTarget.value;
    const bookedPpx = this.bookedPpxTarget.innerHTML;

    this.inputtedEventPpxTarget.innerHTML = ppx;

    if (ppx && ppx.length > 0 && ppx != bookedPpx) {
      this.ppxWarningTarget.classList.remove("hidden");
    } else {
      this.ppxWarningTarget.classList.add("hidden");
    }
  }
}