import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['field']
  static values = { 
    preselected: Boolean,
    staffed: Boolean
  }

  check(e) {
    if (this.preselectedValue == true) {
      alert("Extra time cannot be removed once added, sorry about this!")
      this.preventUncheck(e, true)
      return false
    } else if (this.staffedValue == true) {
      alert("Extra time cannot be added to activities that have already been staffed with freelancers, sorry about this!")
      this.preventUncheck(e, false)
      return false
    } else {
      return true
    }
  }

  preventUncheck(e, checked) {
    e.preventDefault()
    $(this.fieldTarget).prop('checked', checked)
  }
}