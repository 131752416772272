import { Controller } from "stimulus";

export default class extends Controller {
  static values = { url: String }
  
  connect() {
    this.interval = setInterval(() => {
      this.poll()
    }, 5000)
  }

  poll() {
    $.getJSON(this.urlValue, (response) => {
      if (response.status == 'ready') {
        clearInterval(this.interval)
        window.location.reload()
      }
    })
  }
}