import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "currentAdmin",
                      "nameInput",
                      "button" ];
  
  validateName(event){
    const currentAdmin = this.currentAdminTarget.innerHTML.toUpperCase()
    const nameInput = this.nameInputTarget.value.toUpperCase()

    if (currentAdmin === nameInput) {
      if (!confirm("This action can't be undone. Are you sure you want to cancel this activity?")) {
        event.preventDefault()
      }
    } else {
      event.preventDefault()
      alert(nameInput + " does not match the last name we have saved. This event has not been cancelled.");
    }
  }
}
