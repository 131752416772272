import { Controller } from "stimulus";

export default class extends Controller {
  toggle(e) {
    let type = e.target.getAttribute("data-type")
    let checkbox = this.element.querySelector(`input[value="${type}"]`)
    checkbox.checked = !checkbox.checked
    checkbox.form.requestSubmit()
  }
}
