import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  markAllAsRead(event) {
    $.ajax({
      type: "POST",
      url: this.data.get("url-all"),
      data: {},
      success: function () {
        $("[data-action='click->notifications#markAsRead']")
          .removeClass("bg-indigo-600")
          .addClass("bg-gray-200");
        $(".js-new-notification-bubble").addClass("hidden");
        alert("All notifications marked as read!");
      },
      error: function (err) {
        console.log("failure");
      },
    });
  }

  markAsRead(event) {
    const currentTarget = $(event.currentTarget);
    $.ajax({
      type: "POST",
      url: currentTarget.data("url"),
      data: {},
      success: function () {
        currentTarget.removeClass("bg-indigo-600").addClass("bg-gray-200");
        if (
          !$(".bg-indigo-600[data-action='click->notifications#markAsRead']")
            .length
        ) {
          $(".js-new-notification-bubble").addClass("hidden");
        }
      },
      error: function (err) {
        console.log("failure");
      },
    });
  }
}
