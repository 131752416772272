import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    setTimeout(function () {
      window.location.reload();
    }, 5000);
  }
}
