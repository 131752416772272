import { Controller } from "stimulus";
import Trix from "trix";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor
  }

  useTemplate(e) {
    let hasContent = this.editor.getDocument().getLength() > 1;
    if (hasContent && !confirm("Are you sure?  This will replace all current message content.")) {
      return;
    }

    let el = e.target
    this.editor.recordUndoEntry('Content updated')
    this.editor.setSelectedRange([0, this.editor.getDocument().getLength()])
    this.editor.insertHTML(el.getAttribute("data-template"))
  }
}
