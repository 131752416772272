import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "output"]
  static values = { "url": String }

  calculate(e) {
    e.preventDefault()

    if (this.hasInputTarget && this.hasOutputTarget && this.urlValue) {
      const address = this.inputTarget.value

      if (address.length > 0) {
        this.outputTarget.classList.remove("hidden")
        this.outputTarget.innerText = "Calculating distance..."
        this.grabDistance()
      } else {
        alert("Please enter your start location")
      }
    }
  }

  grabDistance() {
    $.ajax({
      type: "POST",
      url: this.urlValue,
      dataType: "json",
      data: {
        address: this.inputTarget.value
      },
      success: (response) => {
        const msg = response.error ? response.error : `It will take approximately <b>${response.walking_time}</b> to walk to the event area from <b>${response.from_address}</b>`
        this.outputTarget.innerHTML = msg
      },
      error: function (err) {
        console.log("failure");
      }
    });

  }
}