import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.hide();
  }

  connect() {
    setTimeout(() => {
      this.show();
    }, 200);
  }

  close() {
    this.hide();
    setTimeout(() => {
      this.element.remove();
    }, 500);
  }

  show() {
    this.element.setAttribute(
      "style",
      "transition: 0.5s; transform:translate(0, 0);"
    );
  }

  hide() {
    this.element.setAttribute(
      "style",
      "transition: 1s; transform:translate(0, 400px);"
    );
  }
}
