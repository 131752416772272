import Flatpickr from 'stimulus-flatpickr'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  close(selectedDates, dateStr, instance) {
    let form = instance.element.form
    if (!form) return

    // clear type (which contradicts this form)
    let typeInput = form.querySelector("[data-target='date-range.typeInput']")
    if (typeInput) { typeInput.value = null }

    // submit
    instance.element.form.requestSubmit()
  }
}
