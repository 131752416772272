import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "uppyConnect"
  ]

  static values = {
    maxQuestions: Number,
  }

  clearPreview() {
    const maxAnswersAllowed = 4
    $(document.getElementById('question-preview')).val('');
    for (let i=1; i<=maxAnswersAllowed; i++) {
      $(document.getElementById(`answer-preview-${i}`)).val('')
    }
  }

  connect() {
    this.sortPosition();
    this.clearPreview();

    setTimeout(() => {
      document.dispatchEvent(new Event('uppy:reinit', { 'bubbles': true }));
    }, 500)
  }

  uppyConnect() {
    document.dispatchEvent(new Event('uppy:reinit', { 'bubbles': true }));
  }

  focusPreview(event) {
    const maxAnswersAllowed = 4
    event.preventDefault();
    this.clearPreview();
    this.sortPosition();

    var questionSelected = $(event.currentTarget)
      .closest(".nested-fields")
      .find(".js-question-position")
      .text();

    // set preview explaination
    $(document.getElementById("preview-explaination")).addClass("hidden")
    document.getElementById("preview-id").innerHTML = `Previewing Question #<span id="preview-value">${questionSelected}</span>`;

    // set question text
    var questionText = $(event.currentTarget).closest(".nested-fields").find(".js-question-text").val()
    $(document.getElementById("question-preview")).val(questionText)

    // add/remove answers if multi choice
    const id = $($(event.target)
      .closest(".nested-fields")
      .find(".js-position-input")[0])
      .attr("name")
      .match(/\d+/g)[0];
    var checked = document.getElementById(`activity_activity_custom_questions_attributes_${id}_multiple_choice`).checked;
    if (checked) {
      for (let i=2; i<=maxAnswersAllowed; i++) {
        $(document.getElementById(`answer-preview-${i}`)).removeClass("hidden")
      }
    } else {
      for (let i=2; i<=maxAnswersAllowed; i++) {
        $(document.getElementById(`answer-preview-${i}`)).addClass("hidden")
      }
    }
    this.copyAnswers(event);

    // copy image if preset
    let imageSrc = $(event.target).closest(".nested-fields").find(".js-image-preview").attr("src")
    if (imageSrc == undefined || imageSrc == "") {
      $(document.getElementById("image-preview")).addClass("hidden")
    } else {
      document.getElementById("image-preview").src = imageSrc
      $(document.getElementById("image-preview")).removeClass("hidden")
    }

    var img = $(event.target).closest(".nested-fields").find(".js-image-preview")[0]
    let observer = new MutationObserver((changes) => {
      changes.forEach(change => {
          if(change.attributeName.includes('src')){
            document.getElementById("image-preview").src = img.src
            $(document.getElementById("image-preview")).removeClass("hidden")
          }
      });
    });

    observer.observe(img, {attributes : true});
  }

  copyQuestion(event) {
    var questionNumber = $(event.currentTarget).closest(".nested-fields").find(".js-question-position").text();
    var currentPreview = $(document.getElementById("preview-value")).text();
    if (questionNumber == currentPreview) {
      $(document.getElementById("question-preview")).val($(event.currentTarget).val())
    }
  }

  copyAnswers(event, questionNumber = null, correctAnswerVal = null, incorrectAnswers = null) {
    const maxAnswersAllowed = 4
    if (questionNumber == null) {
      questionNumber = $(event.target).closest(".nested-fields").find(".js-question-position").text();
    }

    var currentPreview = $(document.getElementById("preview-value")).text();
    if (questionNumber == currentPreview) {
      // copy correct answer
      if (correctAnswerVal == null) {
        correctAnswerVal = $(event.target).closest(".nested-fields:visible").find(".js-correct-answer").val()
      }

      const id = $($(event.target)
      .closest(".nested-fields")
      .find(".js-position-input")[0])
      .attr("name")
      .match(/\d+/g)[0];
      // let shuffleAnswers = $(`input[name="activity[activity_custom_questions_attributes][${id}][shuffle_answers]"]`).val()
      let shuffleAnswers = document.getElementById(`activity_activity_custom_questions_attributes_${id}_shuffle_answers`).checked;
      let correctAnswerPosition = $(`#activity_activity_custom_questions_attributes_${id}_correct_answer_position`).val()

      // if shuffle answers is true then return correct answer to position 1
      if (shuffleAnswers) {
        correctAnswerPosition = 1
      }
      
      $(document.getElementById(`answer-preview-${correctAnswerPosition}`)).val(correctAnswerVal);
      $(document.getElementById(`answer-preview-${correctAnswerPosition}`)).removeClass("hidden");
      $(document.getElementById(`answer-preview-blob-${correctAnswerPosition}`)).removeClass("hidden")

      let answerCount = 0
      if (incorrectAnswers == null) {
        incorrectAnswers = $(event.target).closest('.nested-fields:visible').find('.js-answer-text')
      }
      // simple array of other available positions (as correct position can change)
      let answerCountArray = Array.from({length: incorrectAnswers.length + 1}, (x, i) => i + 1).filter(i => i != correctAnswerPosition)

      incorrectAnswers.each((idx, item) => {
        let itemVal = item.childNodes[1].value
        $(document.getElementById(`answer-preview-${answerCountArray[idx]}`)).val(itemVal);
        $(document.getElementById(`answer-preview-${answerCountArray[idx]}`)).removeClass("hidden")
        $(document.getElementById(`answer-preview-blob-${answerCountArray[idx]}`)).removeClass("hidden")
        answerCount = idx+2
      })
      if (answerCount==0) {
        for (let j=2; j<=maxAnswersAllowed; j++) {
          $(document.getElementById(`answer-preview-${j}`)).addClass("hidden")
        }
      } else {
        for (answerCount+=1; answerCount<=maxAnswersAllowed; answerCount++) {
          $(document.getElementById(`answer-preview-${answerCount}`)).addClass("hidden")
          $(document.getElementById(`answer-preview-blob-${answerCount}`)).addClass("hidden")
        }
      }
    }
  }

  changeCorrectAnswerPosition(event) {
    let incorrectAnswersCount = $(event.target).closest('.nested-fields:visible').find('.js-answer-text').length
    if ($(event.target).val() < 1) {
      alert("Correct answer position cannot be less than 1")
      $(event.target).val(1)
    } else if ($(event.target).val() > incorrectAnswersCount + 1) {
      alert("Correct answer position out of bounds");
      $(event.target).val(incorrectAnswersCount + 1)
    } else {
      this.copyAnswers(event)
    }
  }

  toggleShuffleAnswers(event) {
    var $this = this;
    var $event = event;
    setTimeout(function() {
      const id = $($($event.target)
          .closest(".nested-fields")
          .find(".js-position-input")[0])
          .attr("name")
          .match(/\d+/g)[0];
      var checked = document.getElementById(`activity_activity_custom_questions_attributes_${id}_shuffle_answers`).checked;
      if (checked) {
        $($($event.target)
        .closest(".nested-fields")
        .find(".correct-answer-position-container")[0])
        .addClass('hidden')
        .val(0)
      } else {
        $($($event.target)
          .closest(".nested-fields")
          .find(".correct-answer-position-container")[0])
          .removeClass('hidden')
          .val(1)
      }
      $this.copyAnswers($event)
    }, 10)
  }

  removeQuestionImage(event) {
    event.preventDefault();

    if (!confirm("Are you sure?")) { return; }

    const id = $($(event.target)
    .closest(".nested-fields")
    .find(".js-position-input")[0])
    .attr("name")
    .match(/\d+/g)[0];

    $(`input[name="activity[activity_custom_questions_attributes][${id}][image]"]`).remove();
    $(document.getElementById("image-preview")).attr("src", "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");

    let controller = $(event.currentTarget).closest("[data-controller='uppy']")[0]
    controller.querySelectorAll('[data-behavior="uppy-success"]').forEach((el) => {
      el.classList.add("hidden")
    })
  }

  toggleMultipleChoice(event) {
    // NOTE: this isn't used currently, but the code is here is case we want to support multi choice questions in future.

    var $this = this;
    var $event = event;
    setTimeout(function() {
      const id = $($($event.target)
          .closest(".nested-fields")
          .find(".js-position-input")[0])
          .attr("name")
          .match(/\d+/g)[0];
      var checked = document.getElementById(`activity_activity_custom_questions_attributes_${id}_multiple_choice`).checked;
      if (checked) {
        // add first incorrect answer
        const number = $($event.target)
        .closest(".nested-fields")
        .find(".js-question-position")
        .text();
        $($event.target).closest(".nested-fields").find(".js-add-answer-button").removeClass("hidden")

        $this.addNewAnswerInput($event, number);
      } else {
        // remove all incorrect answers
        const answers = $($event.target).closest(".js-answer-list").find(".js-answer-text");
        $($event.target).closest(".nested-fields").find(".js-add-answer-button").addClass("hidden")
        for (var i=0; i < answers.length; i++) {
          answers[i].remove();
        }
      }
      $this.copyAnswers($event);
    }, 10)
  }

  addAnswer(event) {
    const number = $(event.currentTarget)
      .closest(".nested-fields")
      .find(".js-question-position")
      .text();

    if (event.type == "keydown" && event.keyCode == 13) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const list = $(event.currentTarget)
        .closest(".js-answer-list")
        .find(".js-answer-text");
      const idx = list.index(
        $(event.currentTarget).parent(".js-answer-text")[0]
      );

      if (idx == list.length - 1) {
        this.addNewAnswerInput(event, number);
      } else {
        $(list[idx + 1])
          .find("input")
          .focus();
      }
      return false;
    }

    if (event.type == "click") {
      this.addNewAnswerInput(event, number);
    }
  }

  addNewAnswerInput(event, number) {
    const question = $($(".nested-fields")[number - 1]);
    let answerLength = question.find(".js-answer-text").length

    if (answerLength >= 2) {
      question.find(".js-add-answer-button").addClass("hidden")
    } else if (answerLength >= 3) {
      alert("Maximum number of answers allowed for each question is 4.");
      return false;
    }

    const id = $($(event.target)
      .closest(".nested-fields")
      .find(".js-position-input")[0])
      .attr("name")
      .match(/\d+/g)[0];
    let lastAnswerId = 0
    if (question.find(".js-answer-text")[answerLength-1]) {
      lastAnswerId = Number(question.find(".js-answer-text")[answerLength-1].id.match(/\d+/g)[0])
    }

    const elementToClone = `
    <div id="answer-${lastAnswerId+1}" class="js-answer-text flex items-stretch space-x-2">
      <input data-action="keyup->unsaved-changes#formIsChanged keyup->custom-questions#copyAnswers keydown->custom-questions#addAnswer" placeholder="Enter an incorrect answer here..." class="border-gray-400 rounded-md w-full" type="text" name="activity[activity_custom_questions_attributes][${id}][incorrect_answers][]">
      <a class="cursor-pointer justify-center inline-flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 active:bg-red-700 transition ease-in-out duration-150" data-action="click->custom-questions#removeAnswer click->unsaved-changes#formIsChanged" href="#">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path>
        </svg>
      </a>
    </div>
    `;

    $(elementToClone)
      .clone()
      .appendTo(question.find(".js-answer-list"))
      .find("input")
      .val("")
      .focus();
  }

  removeAnswer(event) {
    event.preventDefault()

    const numberOfAnswers = $(event.target)
      .closest(".js-answer-list")
      .find(".js-answer-text").length;

    if (numberOfAnswers <= 3) {
      $(event.target).closest(".nested-fields").find(".js-add-answer-button").removeClass("hidden")
    }
    const id = $($(event.target)
      .closest(".nested-fields")
      .find(".js-position-input")[0])
      .attr("name")
      .match(/\d+/g)[0];
    var multipleChoice = document.getElementById(`activity_activity_custom_questions_attributes_${id}_multiple_choice`).checked;

    let questionNumber = $(event.target).closest(".nested-fields").find(".js-question-position").text();
    let correctAnswerVal = $(event.target).closest(".nested-fields:visible").find(".js-correct-answer").val()
    let questionId = $(event.currentTarget).parent(".js-answer-text")[0].id
    let incorrectAnswers = $(event.target).closest('.nested-fields:visible').find('.js-answer-text').not(`#${questionId}`)

    if (numberOfAnswers <= 1 && multipleChoice) {
      alert("There must be at least one incorrect answer for multiple choice questions.");
    } else {
      $(event.currentTarget).parent(".js-answer-text").remove();
      this.copyAnswers(event, questionNumber, correctAnswerVal, incorrectAnswers)
    }
  }

  addNewQuestion(event) {
    const numberOfQuestions = $(event.target)
      .closest(".all-questions")
      .find(".nested-fields").length;
    
    if (numberOfQuestions >= this.maxQuestionsValue) {
      alert("You cannot have more than " + this.maxQuestionsValue + " questions.");
      event.preventDefault();
      event.stopImmediatePropagation();
    } else {
      this.sortPosition();
      document.dispatchEvent(new Event('uppy:reinit', { 'bubbles': true }));
    }  
  }

  removeQuestion(event) {
    const numberOfQuestions = $(event.target)
      .closest(".all-questions")
      .find(".nested-fields").length;
    
    if (numberOfQuestions <= 1) {
      alert("There must be at least one custom question.")
      event.preventDefault();
      event.stopImmediatePropagation();
      return;
    }

    if (!confirm("Are you sure?")) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  sortPosition() {
    $(".nested-fields:visible").each((idx, item) => {
      const number = idx + 1;
      if ($(item).data("new-record") == true) {
        const id = $($(item).find(".js-position-input")[0])
          .attr("name")
          .match(/\d+/g)[0];
        $(item)
          .find(".js-answer-text input")
          .attr(
            "name",
            `activity[activity_custom_questions_attributes][${id}][incorrect_answers][]`
          );
        $(item)
          .find(".js-correct-answer")
          .attr(
            "name",
            `activity[activity_custom_questions_attributes][${id}][correct_answer]`
          );
        $(item)
          .find(".js-question-text")
          .attr(
            "name",
            `activity[activity_custom_questions_attributes][${id}][question_text]`
          );
        $(item)
          .find(".js-uppy-image")
          .attr(
            "data-uppy",
            `activity[activity_custom_questions_attributes][${id}][image]`
          )
          .attr(
            "name",
            `activity[activity_custom_questions_attributes][${id}][image]`            
          )
      }

      $(item).find(".js-position-input").val(number);
      $(item).find(".js-question-position").text(number);
    });
  }
}
