import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["collapsed", "expanded"]
  static values = { show: Boolean }

  connect() {
    if (this.showValue) {
      this.show()
    }
  }

  show() {
    this.collapsedTarget.classList.add("hidden");
    this.expandedTarget.classList.remove("hidden");
  }

  hide() {
    this.expandedTarget.classList.add("hidden");
    this.collapsedTarget.classList.remove("hidden");
  }
}
