// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
import { Turbo, cable } from "@hotwired/turbo-rails";
import Uppy from '@uppy/core';
import ActiveStorageUpload from '@excid3/uppy-activestorage-upload';
import Dashboard from '@uppy/dashboard';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("./stylesheet.scss");

//
import LocalTime from "local-time";
LocalTime.start();

//
import "alpine-hotwire-turbo-adapter";
import "alpinejs";

//
import tippy from "tippy.js";

$(document).on("turbo:load", function () {
  tippy("[data-tippy-content]", { animation: "fade", arrow: true });
});

require("trix");
require("@rails/actiontext");

import "controllers";

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

let okToSetPreview = true;
let uppies = []

const loadUppy = () => {
  document
    .querySelectorAll('[data-uppy]')
    .forEach((element) => setupUppy(element))
}

function loadDelayedUppy() {
  setTimeout(loadUppy, 200)
}

document.addEventListener('turbo:load', loadDelayedUppy)
document.addEventListener('modal:load', loadDelayedUppy)
document.addEventListener('uppy:reinit', () => {
  uppies.forEach((u) => u.close)
  loadDelayedUppy()
})


function setupUppy(element) {
  let trigger = element.querySelector('[data-behavior="uppy-trigger"]')
  if (trigger.getAttribute("data-uppy-configured")) {
    // already configured, so let's return to prevent duplicate binds
    return
  }
  trigger.setAttribute("data-uppy-configured", "true")
  let form = element.closest('form')
  let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
  let field_name = element.dataset.uppy

  trigger.addEventListener("click", (event) => event.preventDefault())

  let uppy = new Uppy({
    autoProceed: true,
    allowMultipleUploads: false,
    restrictions: {
      allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif']
    }
  })

  uppy.use(ActiveStorageUpload, {
    directUploadUrl: direct_upload_url
  })

  uppy.use(Dashboard, {
    trigger: trigger,
    closeAfterFinish: true,
  })

  uppy.on('complete', (result) => {
    element.querySelectorAll('[data-pending-upload').forEach(el => el.parentNode.removeChild(el))

    result.successful.forEach(file => {
      appendUploadedFile(element, file, field_name)
      setPreview(element, file)
      setSuccess(element, file)
    })

    uppy.cancelAll({ reason: 'user' })
    uppies.push(uppy)
  })
}

function appendUploadedFile(element, file, field_name) {
  const hiddenField = document.createElement('input')

  hiddenField.setAttribute('type', 'hidden')
  hiddenField.setAttribute('name', field_name)
  hiddenField.setAttribute('data-pending-upload', true)
  hiddenField.setAttribute('value', file.response.signed_id)

  element.appendChild(hiddenField)
}

function setPreview(element, file) {
  const reader = new FileReader();
  reader.readAsDataURL(file.data); 
  reader.onloadend = function() {
    let preview = element.querySelector('[data-behavior="uppy-preview"]')
    if (preview) {
      // result includes identifier 'data:image/png;base64,' plus the base64 data
      preview.src = reader.result;
    }  
  }
}

function setSuccess(element, file) {
  element.querySelectorAll('[data-behavior="uppy-success"]').forEach((el) => {
    el.classList.remove("hidden")
  })
}

